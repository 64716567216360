import {Link}  from "gatsby"
import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ServiceCard from "../../Home/ServiceCard/ServiceCard"
import  './VideoSuggestions.scss'; 
import { useStaticQuery, graphql } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";
import { getVideoId } from "../../utils";
import PlayVideo from "../../Play/PlayVideo";

const videoRef = React.createRef(null);

const SimilarVideos = ( props ) => {
    const [isPlaying, setPlaying] = React.useState(false);
    const [videoId, setVideoId] = React.useState("")

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                videos(sort: "Sort", where: {Publish: true}, limit: 6) {
                    id
                    Title
                    URL
                    Tile_Image {
                        url
                        alternativeText
                    }
                    Embed_Video_Url
                    Date
                    Category {
                        Category
                        Sort
                        id
                    }
                }
            }
        }
    `);
  
    const { videos } = data.glstrapi;

    const playVideo = async (url) => {
        const videoId = getVideoId(url);
        await setVideoId(videoId)
        await setPlaying(true);
        playVideoUrl();
    }

    const playVideoUrl = () => {
        var elem = videoRef.current;
        // const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
        // if(fullScreenAvailable) {
        //     if (elem.requestFullscreen) {
        //         elem.requestFullscreen();
        //     } else if (elem.webkitRequestFullscreen) { /* Safari */
        //         elem.webkitRequestFullscreen();
        //     } else if (elem.msRequestFullscreen) { /* IE11 */
        //         elem.msRequestFullscreen();
        //     }
        // } else {
        //     elem.classList.add("fullscreen-video")
        // }
        elem.classList.add("fullscreen-video")
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formClick',
            'formType': 'form-video',
            'formId': 'similar-video',
            'formName': "Similar Video",
            'formLabel': window.location.href
        });
    }

    const closeVideo = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
        setPlaying(false)
    }


    function fullScreenHandler() {
        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if(fullscreenElement == null) {
            setPlaying(false);
        }
    }

    React.useEffect(() => {
        document.addEventListener("fullscreenchange", fullScreenHandler, false);
        document.addEventListener("webkitfullscreenchange", fullScreenHandler, false);
        document.addEventListener("MSFullscreenChange", fullScreenHandler, false);
        document.addEventListener("mozfullscreenchange", fullScreenHandler, false);
    }, []);

    return (
        <div className="similar-properties">
            <Container>
                <Row>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce>
                        <div className="similar-head d-md-flex justify-content-between align-items-center">
                            <h2 className="main-title">Similar Videos</h2>
                            <Link to="/dubai-property-videos/" className="view-more text-uppercase d-none d-md-inline-block">view more</Link>
                        </div>
                    </ScrollAnimation>
                </Row>
            </Container>
            <Container>
                <Row>
                    {videos.filter(c => c.id !== props.id).map((each, i) => 
                        <Col xl={4} md={6} xs={12} className={"insights-slider mb-1"} key={i}>
                            <div className="img-item position-relative">
                                <ScrollAnimation animateIn={i%2 == 0 ? "fadeInLeft" : "fadeInRight"} animateOnce>
                                    <ServiceCard 
                                        cardImg={each.Tile_Image?.url}
                                        imgAlt={each.Title + " - Espace"}
                                        cardDesc={each.Title}
                                        date={each.Date}
                                        video={each.Embed_Video_Url}
                                        playVideo={() => playVideo(each.Embed_Video_Url)}
                                        navigateLink={`/dubai-property-videos/${each.URL}/`}
                                    />
                                </ScrollAnimation>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
            <div ref={videoRef}>
                {videoId && <PlayVideo
                    isOpen={isPlaying}
                    isCloseFunction={setPlaying}
                    videoId={videoId}
                    isAutoPlay={1}
                />}
                <strong className="play-btn close-icon" 
                    onClick={() => { 
                        setPlaying(false); videoRef.current.classList.remove("fullscreen-video")
                    }}>
                    <i className="icon-close"/>
                </strong>
            </div>
        </div>
    )
}



export default SimilarVideos;