import { graphql } from "gatsby";
import React from "react";
import ContactForm from "../components/AllModules/ContactForm/ContactForm";
import SimilarVideos from "../components/AllModules/VideoSuggestions/SimilarVideos";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import SEO from "../components/seo";
import StaticBanner from "../components/StaticBanner/StaticBanner";

const VideoTemplate = (props) => {
    const { video, siteConfig } = props.data.glstrapi;
    return (
        <div>
            <Header/>
            <SEO
                title={video?.Title}
                description={"Learn more about " + video.Title}
                location={props.location}
            />
            <StaticBanner
                areaGuides={false}
                videos={true}
                data={video}
                siteConfig={siteConfig}
            />
            <SimilarVideos
                id={video?.id}
            />
            <ContactForm/>
            <Footer
                popularSearch="Popular_Static"
            />
        </div>
    )
}

export default VideoTemplate;

export const pageQuery = graphql`
    query videoQuery($id: ID!) {
        glstrapi {
            video(id: $id, publicationState: LIVE) {
                id
                URL
                Title
                Tile_Image {
                    url
                }
                Meta_Title
                Meta_Description
                Embed_Video_Url
                Description
                Date
                Category {
                    Category
                    id
                }
            }
            siteConfig {
                Office_Phone
                Office_Image {
                    url
                    alternativeText
                }
                Office_Address
                Office_Email
                Office_Whatsapp
            }
        }
    }
`